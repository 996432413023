import request from "@/api/request";
import getters from "./getter";
import { getToken, setToken, removeToken } from "@/libs/token";
import bowser from "bowser";
const user = {
  namespaced: true,
  state: {
    user: null,
    token: getToken()
  },
  mutations: {
    SET_STATE(state, data) {
      state.user = data;
    },
    SET_TOKEN(state, data) {
      state.token = data;
    },
    CLEAN_STATE(state) {
      state.user = null;
      state.token = null;
    }
  },
  actions: {
    login({ commit, dispatch }, data) {
      return new Promise((resolve, reject) => {
        let userinfo;
        let rememberMe = data.rememberMe;
        try {
          switch (data.type) {
            case "mobile":
              userinfo = dispatch("mobileLogin", {
                mobile: data.mobile,
                code: data.code
              });
              break;
            case "password":
              userinfo = dispatch("passwordLogin", {
                account: data.mobile,
                password: data.password
              });
              break;
            case "officialAccount":
              userinfo = dispatch("officialAccount", {
                code: data.code
              });
              break;
            case "openPlatform":
              userinfo = dispatch("openPlatform", {
                code: data.code
              });
              break;
            case "register":
              break;
            case "info":
              userinfo = dispatch("getinfo");
              break;
          }
          userinfo
            .then(res => {
              const expires = bowser.mobile ? 30 : rememberMe === true ? 7 : 1;
              if (data.type !== "info") {
                setToken(res.data.jwt, expires);
                commit("SET_TOKEN", res.data.jwt);
              }
              const userinfo = res.data.user;
              userinfo.isBindWx = res.data.isBindWx;
              commit("SET_STATE", userinfo);
              // 获取购物车信息
              dispatch("shop/getCart", null, {
                root: true
              });
              resolve(res);
            })
            .catch(err => {
              reject(err);
            });
        } catch (err) {
          reject(err);
        }
      });
    },
    passwordLogin(...args) {
      const data = args[1];
      return new Promise((resolve, reject) => {
        request
          .post("/api/default/password-login", {
            account: data.account,
            password: data.password
          })
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    openPlatform(...args) {
      const data = args[1];
      return new Promise((resolve, reject) => {
        request
          .get("/api/default/open-platform-login", {
            code: data.code
          })
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    officialAccount(...args) {
      const data = args[1];
      return new Promise((resolve, reject) => {
        request
          .get("/api/default/official-account-login", {
            code: data.code
          })
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    mobileLogin(...args) {
      const data = args[1];
      return new Promise((resolve, reject) => {
        request
          .post("/api/default/mobile-login", {
            mobile: data.mobile,
            code: data.code
          })
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    getinfo() {
      return new Promise((resolve, reject) => {
        request
          .get("/api/user/account")
          .then(res => {
            const data = {
              data: {
                user: res.data,
                isBindWx: res.data.isBindWx
              }
            };
            resolve(data);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    logout({ commit }) {
      return new Promise(resolve => {
        removeToken();
        commit("CLEAN_STATE");
        resolve();
      });
    }
  },
  getters
};

export default user;
