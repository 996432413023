import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import wxtool from "@/assets/utils/wx-tool.js";
import { wxShareCommonImg } from "@/assets/utils/config";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "shop-home",
    redirect: () => {
      return process.env.NODE_ENV === "development" ? "/home" : "/products/";
    }
  },
  {
    path: "/home",
    name: "shop-test-home",
    component: () => import("../pages/home")
  },
  {
    path: "/products/:type?",
    name: "product-list",
    component: () => import("../pages/product/list"),
    props: true
  },
  {
    path: "/product/search",
    name: "product-search-list",
    component: () => import("../pages/product/list")
  },
  {
    path: "/product/:id",
    name: "product-detail",
    component: () => import("../pages/product/detail"),
    props: true,
    meta: {
      mobileHeaderShow: false,
      showBottomMenu: false
    }
  },
  {
    path: "/carts",
    name: "cart-list",
    component: () => import("../pages/cart/list"),
    meta: {
      isShowFooter: false,
      requireAuth: true
    }
  },
  {
    path: "/orders/:status?",
    name: "order-list",
    component: () => import("../pages/order/list"),
    meta: {
      requireAuth: true
    }
  },
  {
    path: "/order",
    name: "order-detail",
    component: () => import("../pages/order/detail"),
    meta: {
      mobileHeaderShow: false,
      showBottomMenu: false,
      mobileHeaderTitle: "订单详情",
      requireAuth: true
    }
  },
  {
    path: "/order/create/:id",
    name: "order-create",
    component: () => import("../pages/order/create"),
    props: true,
    meta: {
      isHiddenFooter: true,
      mobileHeaderShow: false,
      mobileHeaderTitle: "填写并核对订单信息",
      showBottomMenu: false,
      requireAuth: true
    }
  },
  {
    path: "/order/checkout",
    name: "order-wxpay-checkout",
    component: () => import("../pages/order/checkout"),
    meta: {
      requireAuth: true
    }
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../pages/login/page"),
    meta: {
      mobileHeaderShow: false,
      showBottomMenu: false,
      mobileFixProductTop: false
    }
  },
  {
    path: "/oauth/redirect",
    name: "wx-redirect-page",
    component: () => import("../pages/login/wx-redirect-uri")
  },
  {
    path: "/collects",
    name: "user-collects",
    component: () => import("../pages//user/collects"),
    meta: {
      requireAuth: true
    }
  },
  {
    path: "/user/home",
    name: "user-console",
    component: () => import("../pages/user/home"),
    meta: {
      requireAuth: true
    }
  },
  {
    path: "/custom",
    name: "product-custom",
    component: () => import("../pages/product/custom"),
    meta: {
      mobileHeaderShow: false,
      mobileHeaderBackBg: "#D2E6DC",
      showBottomMenu: false
    }
  },
  {
    path: "/address",
    name: "user-address",
    component: () => import("../pages/address/list"),
    meta: {
      mobileHeaderShow: false,
      mobileHeaderTitle: "我的地址",
      requireAuth: true
    }
  }
];
const router = new VueRouter({
  mode: "history",
  base: process.env.NODE_ENV === "development" ? "/shop" : process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return {
      x: 0,
      y: 0
    };
  }
});

router.beforeEach((to, from, next) => {
  const flag = to.matched.some(record => record.meta.requireAuth);
  if (to.name !== "login") {
    const path = to.fullPath;
    store.commit("shop/SET_FORWARD", path);
  }
  if (flag) {
    // 需要登录
    if (
      store.state.user.token === null ||
      store.state.user.token === undefined ||
      store.state.user.token === ""
    ) {
      // 去登录
      // router.push({
      //   path: "/login"
      // });
      window.location.href = "http://store.chicforgo.com/login";
    } else {
      next();
    }
  } else {
    next();
  }
});

router.afterEach(to => {
  store.commit("shop/SET_INIT_LINK", document.URL);
  const toName = to.name;
  const selfShare = ["product-list", "product-detail"];
  if (selfShare.indexOf(toName) === -1) {
    // 统一分享到首页
    const title = "馥馥小高官方网站专业软装艺术设计机构";
    const desc = "为各领域的软装设计需求提供量身定制的专业服务";
    const link = "http://store.chicforgo.com/";
    const imgUrl = wxShareCommonImg;
    wxtool.init().then(wx => {
      wx.updateAppMessageShareData({
        title,
        desc,
        link,
        imgUrl,
        success() {
          console.log("分享成功");
        }
      });
      wx.updateTimelineShareData({
        title,
        desc,
        link,
        imgUrl,
        success() {
          console.log("分享成功");
        }
      });
    });
  }
});

export default router;
