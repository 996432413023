import axios from "axios";
import store from "@/store";
import qs from "qs";
import { Message } from "element-ui";
import bowser from "bowser";
import router from "@/shop/router";
import * as configs from "@/assets/utils/config";

//? "http://api.forgo.cn"
const baseURL =
  process.env.NODE_ENV === "development"
    ? "http://api.chicforgo.com"
    : "http://api.chicforgo.com";
axios.defaults.baseURL = baseURL;

const service = axios.create({
  baseURL: baseURL,
  timeout: 5000
});

service.interceptors.request.use(
  config => {
    const tempConfig = config;
    if (store.state.user.token) {
      tempConfig.headers["Authorization"] = `Bearer ${store.state.user.token}`;
    }
    tempConfig.headers.post["Content-Type"] =
      "application/x-www-form-urlencoded";
    if (tempConfig.method === "post") {
      tempConfig.data = qs.stringify(tempConfig.data);
    }
    // 当前设备
    tempConfig.headers["Dev"] = bowser.mobile ? "wap" : "web";
    tempConfig.headers["In-Wei-xin"] = configs.isInWx;
    return tempConfig;
  },
  error => {
    console.error(error);
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  response => {
    const res = response.data;
    if (res.status !== 0) {
      const message =
        res.error instanceof Array ? res.error.join("\r\n") : res.error;
      if (res.status === 401) {
        // 去登陆
        router.push({
          path: "/login"
        });
      } else {
        Message({
          message: message,
          type: "error",
          duration: 3 * 1000
        });
      }
      return Promise.reject(new Error(message) || "Error");
    }
    return res;
  },
  error => {
    console.error(`err${error}`);
    Message({
      message: error.message,
      type: "error",
      duration: 3 * 1000
    });
    return Promise.reject(error);
  }
);

export default service;
