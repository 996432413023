import $ from "jquery";
import Rellax from "rellax";
import NProgress from "nprogress";
import { TweenMax, TimelineLite, TweenLite, Power3, Linear } from "gsap";
import * as PIXI from "pixi.js";
import bowser from "bowser";
let ticking = false;
let defaultBg;
const $window = $(window);
const windowHeight = bowser.mobile ? window.innerHeight : $window.height();
const rate = bowser.mobile ? 1 : 0.95;
const bottomHeight = windowHeight * rate;
function onScroll() {
  if (!ticking) {
    requestAnimationFrame(scrollDo);
    ticking = true;
  }
}

function scrollDo() {
  home2Text();
  scrollOnShow();
  // 产品详情
  const fixSideBar = document.querySelector("#productDetailFixedSideBar");
  const pdbr = document.querySelector("#productDetailRecommend");
  const pdwrapper = document.querySelector("#productDetailWrapper");
  if (fixSideBar) {
    //
    const fsbBcr = fixSideBar.getBoundingClientRect();
    const pdwBcr = pdwrapper.getBoundingClientRect();
    if (fsbBcr.top <= 0) {
      fixSideBar.classList.add("fixed");
      fixSideBar.style.cssText = `top:0;position:fixed;`;
    }
    if (pdwrapper && fixSideBar.classList.contains("fixed")) {
      if (pdwBcr.top >= 0) {
        fixSideBar.classList.remove("fixed");
        fixSideBar.style.cssText = `top:0;`;
      }
    }
    if (pdbr && fixSideBar.classList.contains("fixed")) {
      const pdbrbr = pdbr.getBoundingClientRect();
      if (pdbrbr.top <= fsbBcr.height + 100) {
        fixSideBar.style.top = `${parseInt(fixSideBar.style.top) - 100}px`;
      }
    }
  }
}

function home2Text() {
  const home2Item = Array.from($(".index-project__item"));
  if (home2Item.length > 0) {
    const screenHeight = $window.height();
    const parent = $(".index-projects-container");
    // 开始固定
    if (
      $(".title__copy")
        .eq("0")
        ["0"].getBoundingClientRect().top <=
      screenHeight * 0.5
    ) {
      parent.find(".item__content").css({
        position: "fixed"
      });
    }

    home2Item.forEach(item => {
      const temp = item;
      const imgBcr = temp.querySelector(".item__image").getBoundingClientRect();
      const textBcr = temp
        .querySelector(".white .title__copy")
        .getBoundingClientRect();
      // 处理x轴
      let whiteRight;
      let blackLeft;
      let y;
      let isLeft = false;
      if (
        imgBcr.x - textBcr.x < 0 &&
        imgBcr.x + imgBcr.width - (textBcr.x + textBcr.width) > 0
      ) {
        //文字在图片里面
        whiteRight = "0";
        blackLeft = "100%";
      } else if (
        // 文字会从右边出来
        imgBcr.x - textBcr.x < 0 &&
        imgBcr.x + imgBcr.width - (textBcr.x + textBcr.width) < 0
      ) {
        whiteRight = `${textBcr.width -
          (imgBcr.width - (textBcr.x - imgBcr.x))}px`;
        blackLeft = `${imgBcr.width - (textBcr.x - imgBcr.x)}px`;
      } else if (
        imgBcr.x - textBcr.x > 0 &&
        imgBcr.x + imgBcr.width - (textBcr.x + textBcr.width) > 0
      ) {
        isLeft = true;
        // 文字会从左边边出来
        whiteRight = `${imgBcr.width - textBcr.x}px`;
        blackLeft = `${textBcr.width - (imgBcr.width - textBcr.x)}px`;
      }
      if (imgBcr.top > 0 && imgBcr.top <= screenHeight * 0.5) {
        // 上半部分
        // 处理y轴
        // temp.find('.black .title__copy');
        // temp.find('.white .title__copy');
        y = 0;
      } else if (imgBcr.top < 0 && imgBcr.height + imgBcr.top >= textBcr.top) {
        // imgBcr.top < 0 && -imgBcr.top <= (screenHeight * 0.47)
        // 下半部分
        y = 0;
      } else {
        y = "100%";
      }

      if (isLeft) {
        temp.querySelector(
          ".black .title__copy"
        ).style.cssText = `clip-path: inset(${y} ${whiteRight} 0 0);`;
        temp.querySelector(
          ".white .title__copy"
        ).style.cssText = `clip-path: inset(${y} 0 0 ${blackLeft});`;
      } else {
        temp.querySelector(
          ".white .title__copy"
        ).style.cssText = `clip-path: inset(${y} ${whiteRight} 0 0);`;
        temp.querySelector(
          ".black .title__copy"
        ).style.cssText = `clip-path: inset(${y} 0 0 ${blackLeft});`;
      }

      // console.log(whiteRight, blackLeft);
    });
    // console.log(screenHeight);
  }
}

export function scrollOnShow() {
  const scolls = [...document.querySelectorAll(".scrollObj")].filter(item => {
    const isAnimated = item.classList.contains("animated");
    const { top, height } = item.getBoundingClientRect();
    const isInView = top <= bottomHeight && top > 0;
    if (isAnimated && !isInView && top - height > 0) {
      // item.classList.remove("animated");
    }
    // console.log(top, bottomHeight);
    return !isAnimated && isInView;
  });
  scolls.forEach((item, key) => {
    if (Number(item.dataset.callback) === 1) {
      item.addEventListener("transitionend", function() {
        if (!item.classList.contains("pause")) {
          item.classList.add("pause");
        }
      });
    }
    doshow(item, key);
  });
  function doshow(obj, k) {
    setTimeout(() => {
      $(obj).addClass("animated");
    }, k * 100);
  }

  [...document.querySelectorAll(".in-view")].forEach(item => {
    const effect = item.dataset.effect;
    const { top } = item.getBoundingClientRect();
    const isInView = top <= bottomHeight && top > 0;
    const isInWholeView = Math.abs(top) <= bottomHeight;
    if (effect === "scale") {
      if (isInWholeView) {
        if (
          $window.scrollTop() > 100 &&
          !item.classList.contains("--animate-scale")
        ) {
          item.classList.add("--animate-scale");
        } else if ($window.scrollTop() < 100) {
          item.classList.remove("--animate-scale");
        }
      } else {
        item.classList.remove("--animate-scale");
      }
    } else if (effect === "bg") {
      const color = item.dataset.color;
      if (isInView || top < 0) {
        document.body.style.cssText = `background-color:${color}`;
      } else if ($(window).scrollTop() <= 0) {
        document.body.style.cssText = `background-color:${defaultBg}`;
      }
    }
  });

  ticking = false;
}

function preload(obj) {
  const arr = Array.from(obj);
  return Promise.all(
    arr
      .filter(item => {
        const data = item.dataset;
        return data.src !== "";
      })
      .map(item => {
        const tagName = item.tagName;
        const data = item.dataset;
        const src = data.src;
        const format = Number(data.format);
        const needClass = Number(data.needclass);
        return new Promise((res, rej) => {
          const img = new Image();
          img.onload = () => {
            if (format === 1) {
              const width = `${(img.width / 1080) * 100}vh`;
              item.style.width = width;
            }
            if (needClass === 1) {
              if (img.width === 320) {
                item.classList.add("small");
              } else {
                item.classList.add("big");
              }
            }
            if (tagName === "IMG") {
              item.src = img.src;
              if (img.width === 1300) {
                item.classList.add("fullsize");
              }
            }
            scrollOnShow();
            setTimeout(() => {
              res(img.src);
            }, 100);
          };
          img.onerror = () => {
            rej();
          };
          img.src = src;
        });
      })
  );
}

export function pageLoading() {
  NProgress.configure({
    showSpinner: false,
    template:
      '<div class="bar-container"><div class="bar" role="bar"></div><div class="logo" style="display:none;"></div></div>'
  });
  NProgress.start();
}

export function pageLoaded() {
  NProgress.done();
  scrollOnShow();
}

export function imgPreload() {
  const preloads = document.querySelectorAll(".preload");
  if (preloads.length > 0) {
    return preload(preloads);
  } else {
    return Promise.resolve();
  }
}

export function pageInit() {
  defaultBg = document.body.style.backgroundColor;
  //about
  if ($(".rellax").length > 0) {
    new Rellax(".rellax", {});
    // const rellax = new Rellax(".rellax", {});
    // console.log(rellax);
  }

  window.addEventListener("scroll", onScroll);
}

export const slideShow = {
  init() {
    this.view = document.querySelector(".index-hero-container");
    this.$collection = this.view.querySelector(".jsCollection");
    this.$items = this.view.querySelectorAll(".jsItem");
    this.$canvas = document.querySelector(".jsCanvas");
    // this.$progress = this.view.querySelector('.jsProgress');
    this.$previous = this.view.querySelector(".prev");
    this.$next = this.view.querySelector(".next");
    this.OLD_INDEX = 0;
    this.CURRENT_INDEX = 0;
    this.TOTAL = this.$items.length - 1;
    this.DIRECTION = 0;
    this.IS_MOVING = false;
    this.indicator = 0;
    this.durationAutoplay = 5;
    this.timerAutoplay = 0;
    this.circleTween = 0;
    this.progressCircle = {
      progress: 0
    };

    this.renderer = new PIXI.autoDetectRenderer({
      width: 100,
      height: 100,
      transparent: true,
      antialias: true
    });
    this.stage = new PIXI.Container();
    this.circleGraphic = new PIXI.Graphics();
    this.circleSettings = {
      color: bowser.mobile ? "0xe8e3de" : "0xffffff",
      radius: 10,
      degree: 270,
      initAngle: this.degreeToRadian(270),
      firstDraw: this.degreeToRadian(270)
    };
    this.circleTween = false;
    this.progressCircle = {
      progress: 0
    };
    this.setup();
    this.launch();
  },
  launch() {
    const t = this;
    if (t.TOTAL > 0) {
      const i = t.$items[t.CURRENT_INDEX];
      const u = i.querySelector(".bg");
      TweenLite.fromTo(
        u,
        this.durationAutoplay + 3,
        {
          scale: 1.04
        },
        {
          scale: 1,
          ease: Power3.easeOut,
          delay: 0.2
        }
      );
      setTimeout(() => {
        t.autoplay();
      }, 1100);
      t.animateCircle();
    }
  },
  animateCircle() {
    const t = this;
    this.progressCircle = {
      progress: 0
    };
    this.circleTween = TweenLite.to(
      this.progressCircle,
      this.durationAutoplay + 1,
      {
        immediateRender: false,
        progress: 1,
        ease: Linear.easeNone,
        onUpdate() {
          let e = 360 * t.progressCircle.progress;
          e += t.circleSettings.degree;
          const i = t.degreeToRadian(e);
          t.circleGraphic.clear();
          t.circleGraphic.beginFill(t.circleSettings.color, 1);
          t.circleGraphic.lineStyle();
          t.circleGraphic.arc(
            t.circleSettings.radius,
            t.circleSettings.radius,
            t.circleSettings.radius,
            t.circleSettings.initAngle,
            i
          );
          t.circleGraphic.lineTo(
            t.circleSettings.radius,
            t.circleSettings.radius
          );
          t.circleGraphic.endFill();
          t.renderer.render(t.stage);
        }
      }
    );
  },
  degreeToRadian(t) {
    return (t * Math.PI) / 180;
  },
  goToNext() {
    if (!this.IS_MOVING) {
      this.DIRECTION = 1;
      this.CURRENT_INDEX += 1;
      if (this.CURRENT_INDEX > this.TOTAL) {
        this.CURRENT_INDEX = 0;
      }
      this.move();
    }
  },
  goToPrevious() {
    if (!this.IS_MOVING) {
      this.DIRECTION = -1;
      this.CURRENT_INDEX -= 1;
      if (this.CURRENT_INDEX < 0) {
        this.CURRENT_INDEX = this.TOTAL;
      }
      this.move();
    }
  },
  move() {
    const t = this;
    const e = this.$items[this.OLD_INDEX];
    const i = this.$items[this.CURRENT_INDEX];
    const u = i.querySelector(".bg");
    const h = i.querySelectorAll(".jsTextFx");

    $(".currentNub").text(`0${this.CURRENT_INDEX + 1}`);

    const r = new TimelineLite({
      onComplete() {
        // TweenLite.to(u, t.durationAutoplay, {
        //   scale: 1,
        // });
        t.twIsComplete();
      }
    });
    let o = "";
    let s = "";
    const n = this.view.getBoundingClientRect();
    if (this.DIRECTION > 0) {
      o = `rect(0px,0px,${n.height}px,0px)`;
      s = `rect(0px,${n.width}px,${n.height}px,0px)`;
    } else {
      o = `rect(0px,${n.width}px,${n.height}px,${n.width}px)`;
      s = `rect(0px,${n.width}px,${n.height}px,0px)`;
    }
    if (this.OLD_INDEX !== this.CURRENT_INDEX) {
      this.IS_MOVING = true;
      this.updating();
      TweenLite.to(this.$canvas, 0.3, {
        scale: 0,
        ease: Power3.easeInOut,
        onComplete() {
          t.circleTween.kill();
          t.progressCircle.progress = 0;
        }
      });
      // TweenLite.to();canvas
      r.kill();
      r.call(() => {
        TweenLite.set(i, {
          clip: o
        });
        e.style.zIndex = 2;
        i.style.zIndex = 3;
        i.classList.remove("disabled");
        i.classList.add("enabled");
      });

      TweenLite.fromTo(
        u,
        this.durationAutoplay + 3,
        {
          scale: 1.14
        },
        {
          scale: 1,
          ease: Power3.easeOut,
          delay: 0.2
        }
      );

      r.add([
        // canvas
        TweenLite.to(this.$canvas, 0.35, {
          scale: 1,
          ease: Power3.easeOut,
          delay: 0.35,
          onStart() {
            t.animateCircle();
          }
        }),
        TweenLite.to(i, 0.7, {
          clip: s,
          ease: Power3.easeInOut
        }),
        TweenMax.staggerFromTo(
          h,
          1,
          {
            opacity: 0,
            y: 100
          },
          {
            opacity: 1,
            y: 0,
            delay: 0.5,
            ease: Power3.easeOut
          },
          0.05
        )
        // 进度条
        // TweenLite.fromTo(this.$progress, 0.7, {
        //   x: '-100%',
        // },
        // {
        //   x: '100%',
        //   ease: Power3.easeInOut,
        // }),
      ]);

      r.add(() => {
        TweenLite.set(h, {
          clearProps: "all"
        });
        TweenLite.set(i, {
          clearProps: "clip"
        });
        TweenLite.set(e, {
          clearProps: "clip"
        });
      });

      r.restart();
    }
  },
  twIsComplete() {
    this.IS_MOVING = false;
    this.OLD_INDEX = this.CURRENT_INDEX;
    this.finalize();
    this.autoplay();
  },
  autoplay() {
    const t = this;
    this.timerAutoplay = window.setTimeout(() => {
      t.goToNext();
    }, 1e3 * this.durationAutoplay);
  },
  setup() {
    const that = this;
    that.$canvas.appendChild(this.renderer.view);
    if (that.TOTAL > 0) {
      that.drawCircle();
    }
    that.$previous.addEventListener("click", () => {
      clearTimeout(that.timerAutoplay);
      that.goToPrevious();
    });
    that.$next.addEventListener("click", () => {
      clearTimeout(that.timerAutoplay);
      that.goToNext();
    });
    that.updating();
    that.finalize();
  },
  drawCircle() {
    this.circleGraphic.beginFill(this.circleSettings.color, 1);
    this.circleGraphic.arc(
      this.circleSettings.radius,
      this.circleSettings.radius,
      this.circleSettings.radius,
      this.circleSettings.initAngle,
      this.circleSettings.firstDraw
    );
    this.circleGraphic.lineTo(
      this.circleSettings.radius,
      this.circleSettings.radius
    );
    this.circleGraphic.endFill();
    this.stage.addChild(this.circleGraphic);
    this.renderer.render(this.stage);
  },
  updating() {
    if (this.CURRENT_INDEX > 0) {
      this.view.classList.add("is--scrolling");
    } else {
      this.view.classList.remove("is--scrolling");
    }
  },
  finalize() {
    if (this.$items.length > 0) {
      const t = this.$items[this.CURRENT_INDEX];
      for (let e = 0; e < this.$items.length; e += 1) {
        const i = this.$items[e];
        if (i.classList.contains("disabled")) {
          i.classList.remove("disabled");
        }
        if (i.classList.contains("enabled")) {
          i.classList.remove("enabled");
        }
        i.style.zIndex = 0;
        if (e !== this.CURRENT_INDEX) {
          i.classList.add("disabled");
        }
      }
      t.classList.add("enabled");
      t.style.zIndex = 1;
    }
  }
};
