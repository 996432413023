export function isNull(val) {
  return (
    val === "" ||
    val === undefined ||
    val === null ||
    (val instanceof Array && val.length === 0)
  );
}

export function IEVersion() {
  const { userAgent } = navigator; // 取得浏览器的userAgent字符串
  // 判断是否IE<11浏览器
  const isIE =
    userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1;
  const isEdge = userAgent.indexOf("Edge") > -1 && !isIE; // 判断是否IE的Edge浏览器
  const isIE11 =
    userAgent.indexOf("Trident") > -1 && userAgent.indexOf("rv:11.0") > -1;
  if (isIE) {
    const reIE = new RegExp("MSIE (\\d+\\.\\d+);");
    reIE.test(userAgent);
    const fIEVersion = parseFloat(RegExp.$1);
    if (fIEVersion === 7) {
      return 7;
    }
    if (fIEVersion === 8) {
      return 8;
    }
    if (fIEVersion === 9) {
      return 9;
    }
    if (fIEVersion === 10) {
      return 10;
    }
    return 6; // IE版本<=7
  }
  if (isEdge) {
    // return 'edge';// edge
    return -1;
  }
  if (isIE11) {
    return 11; // IE11
  }
  return -1; // 不是ie浏览器
}

export function isWx() {
  const ua = window.navigator.userAgent.toLowerCase();
  return ua.match(/micromessenger/i) || ua.match(/wechatdevtools/i);
}

export function sliceGroup(array, count, limit) {
  const temp = [];
  let index = 0;
  while (index < (limit || array.length)) {
    temp.push(array.slice(index, (index += count)));
  }
  return temp;
}

export function isMobile(mobile) {
  const reg = /^0{0,1}(1)[0-9]{10}$/;
  return reg.test(mobile);
}
