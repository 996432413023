import Vue from "vue";
import Vuex from "vuex";
import request from "@/api/request";
import shop from "./modules/shop";
import user from "./modules/user";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loading: false,
    projectTypes: null,
    addInfo: null
  },
  getters: {
    aboutTop: state => {
      if (state.addInfo) {
        return state.addInfo.find(item => item.key_text === "about:top:banner");
      } else {
        return null;
      }
    },
    aboutBottom: state => {
      if (state.addInfo) {
        return state.addInfo.find(item => item.key_text === "about:bottom:img");
      } else {
        return null;
      }
    },
    jobMail: state => {
      if (state.addInfo) {
        return state.addInfo.find(item => item.key_text === "job:mail");
      } else {
        return null;
      }
    },
    jobTel: state => {
      if (state.addInfo) {
        return state.addInfo.find(item => item.key_text === "job:tel");
      } else {
        return null;
      }
    }
  },
  mutations: {
    PAGE_LOADING(state) {
      state.loading = true;
    },
    PAGE_LOADED(state) {
      state.loading = false;
    },
    SET_TYPES(state, val) {
      state.projectTypes = val;
    },
    SET_ADD_INFO(state, val) {
      state.addInfo = val;
    }
  },
  actions: {
    getTypes({ commit, state }) {
      return new Promise((resolve, reject) => {
        if (state.projectTypes) {
          resolve();
        } else {
          request
            .get("/api/product/types")
            .then(res => {
              const lists = res.data.lists;
              commit("SET_TYPES", lists);
              resolve();
            })
            .catch(() => {
              reject();
            });
        }
      });
    },
    getInfo({ state, commit }) {
      return new Promise((resolve, reject) => {
        if (state.addInfo) {
          resolve();
        } else {
          request
            .get("/api/default/info")
            .then(res => {
              const info = res.data.info;
              commit("SET_ADD_INFO", info);
              resolve();
            })
            .catch(() => {
              reject();
            });
        }
      });
    }
  },
  modules: {
    shop,
    user
  }
});
