export const payWays = [
  {
    name: "支付宝",
    icon: require("@/assets/images/shop/icon-alipay.png"),
    val: 1
  },
  {
    name: "微信支付",
    icon: require("@/assets/images/shop/icon-wechat-pay.png"),
    val: 2
  }
];

export const optionType = [
  {
    type: 1,
    name: "颜色"
  },
  {
    type: 2,
    name: "尺寸"
  }
];

export const orderStatus = {
  1: "待付款",
  2: "商家备货中",
  3: "已发货",
  // 4: "确认收货",
  4: "订单已经完成",
  5: "已经完成",
  11: "订单已超时"
};

export const wxShareCommonImg =
  "http://store.chicforgo.com/static/images/for-wx-share.png";

const ua = navigator.userAgent.toLowerCase();
export const isInWx = ua.match(/micromessenger/i) == "micromessenger";
