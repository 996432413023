import wx from "@/libs/jsweixin-1.6.0.js";
import request from "@/api/request";
import store from "@/store";
export default {
  init() {
    const url = store.state.shop.initLink;
    return new Promise((resolve, reject) => {
      request
        .get("/api/default/get-signature", {
          url: encodeURIComponent(url)
        })
        .then(res => {
          const data = res.data;
          wx.config({
            debug: process.env.NODE_ENV === "development",
            appId: data.appId,
            timestamp: data.timestamp,
            nonceStr: data.nonceStr,
            signature: data.signature,
            jsApiList: data.jsApiList
          });
          wx.ready(() => {
            resolve(wx);
          });
        })
        .catch(err => {
          wx.error(err => {
            console.log(err);
          });
          reject(err);
        });
    });
  }
};
