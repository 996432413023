import request from "@/api/request";
import Cookies from "js-cookie";

const shop = {
  namespaced: true,
  state: {
    types: [],
    accountModalShow: false,
    accountModalType: null,
    //验证码倒计时,
    countRest: 0,
    // 登录之前所在链接
    beforeLoginAt: "",
    // 购物车
    carts: [],
    // 收货地址
    addresses: [],
    // 直接购买form
    directForm: Cookies.getJSON("DIRECTFORM"),
    // 用于分享
    initLink: null,
    //
    forwardLink: null,
    fixedCartShow: false
  },
  mutations: {
    OPEN_CART(state) {
      state.fixedCartShow = true;
    },
    CLOSE_CART(state) {
      state.fixedCartShow = false;
    },
    SET_FORWARD(state, data) {
      state.forwardLink = data;
    },
    SET_INIT_LINK(state, data) {
      state.initLink = data;
    },
    SET_DIRECTFORM: (state, data) => {
      Cookies.set("DIRECTFORM", data);
      state.directForm = data;
    },
    CLEAR_DIRECTFORM: state => {
      Cookies.remove("DIRECTFORM");
      state.directForm = null;
    },
    SET_COUNTDOWN: (state, data) => {
      state.countRest = data;
    },
    COUNT_REDUCE: state => {
      state.countRest--;
    },
    SET_MENU: (state, data) => {
      state.types = data;
    },
    SHOW_MODAL: state => {
      state.accountModalShow = true;
    },
    HIDDEN_MODAL: state => {
      state.accountModalShow = false;
    },
    SET_MODAL_TYPE: (state, data) => {
      // login 手机登录
      // wx-login 微信登录
      // password-login 密码登录
      // reset-password 重置密码
      // register 注册
      state.accountModalType = data;
    },
    ADD_ADDRESS(state, data) {
      state.addresses.push(...[data]);
    },
    UPDATE_ADDRESS(state, data) {
      const addresses = state.addresses.map(item => {
        let temp = item;
        if (Number(item.id) === Number(data.id)) {
          temp = data;
        }
        return temp;
      });
      state.addresses = addresses;
    },
    DEL_ADDRESS(state, id) {
      const addresses = state.addresses.filter(
        item => Number(item.id) !== Number(id)
      );
      state.addresses = addresses;
    },
    SET_DEFAULT(state, data) {
      const addresses = state.addresses.map(item => {
        const temp = item;
        temp.is_default = Number(item.id) === Number(data.id) ? 1 : 0;
        return temp;
      });
      state.addresses = addresses;
    },
    SET_CART(state, data) {
      state.carts.push(...data);
    },
    UPDATE_CART(state, data) {
      const carts = state.carts.map(item => {
        let temp = item;
        if (Number(item.id) === Number(data.id)) {
          temp = data;
        }
        return temp;
      });
      state.carts = carts;
    },
    DEL_CART(state, id) {
      const carts = state.carts.filter(item => Number(item.id) !== Number(id));
      state.carts = carts;
    }
  },
  actions: {
    getTypes({ commit }) {
      return new Promise((resolve, reject) => {
        request
          .get("/api/shop/types")
          .then(res => {
            const lists = res.data.lists;
            commit("SET_MENU", lists);
            resolve(lists);
          })
          .catch(() => {
            reject();
          });
      });
    },
    getAddress({ state }) {
      return new Promise((resolve, reject) => {
        if (state.addresses.length === 0) {
          request
            .get("/api/user/address/list")
            .then(res => {
              const data = res.data;
              state.addresses = data.list;
              resolve(state.addresses);
            })
            .catch(err => {
              reject(err);
            });
        } else {
          resolve(state.addresses);
        }
      });
    },
    // 购物车
    getCart({ commit }) {
      return new Promise((resolve, reject) => {
        request
          .get("/api/user/cart")
          .then(res => {
            const data = res.data;
            if (data.list.length > 0) {
              commit("SET_CART", data.list);
            }
            resolve(data);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    cartAdd({ state }, data) {
      return new Promise((resolve, reject) => {
        const carts = state.carts;
        let postData = data;
        let url = "/api/user/cart";
        let type = "add";
        if (carts.length > 0) {
          const existsGoods = carts.find(
            item => item.gid === data.gid && item.extends === data.extends
          );
          if (existsGoods) {
            // 已经存在增加数量
            existsGoods.counts =
              Number(existsGoods.counts) + Number(data.counts);
            existsGoods.total_price =
              Number(existsGoods.total_price) + Number(data.total_price);
            postData = existsGoods;
            url = `${url}/${existsGoods.id}`;
            type = "update";
          }
        }
        request
          .post(url, postData)
          .then(res => {
            const data = res.data;
            if (type === "add") {
              state.carts.push(...[data]);
            } else {
              state.carts = carts;
            }
            resolve(state.carts);
          })
          .catch(err => {
            reject(err);
          });
      });
    }
  }
};

export default shop;
